import type { ComponentType } from 'react'
import type { IPlayerVideo } from '../../atoms/PlayerVideo'
import type { IVideoExpired } from '../../atoms/VideoExpired'
import type { Block } from '../../../types/block'
import type { Video } from '../../../types/video'
import type { IPicture } from '../../../types/pictures'

import { useAmp } from 'next/amp'
import dynamic from 'next/dynamic'
import { mixins, theme } from '../../../styles'
import { PlayerLazyload } from '../../atoms/PlayerLazyload'
import { Icon } from '../../atoms/Icon'
import { Link } from '../../atoms/Link'
import { PreviewVideo } from '../../atoms/PreviewVideo'
import { Picture } from '../Picture'

const PlayerVideo: ComponentType<IPlayerVideo> = dynamic(() =>
  import('../../atoms/PlayerVideo').then((module) => module.PlayerVideo),
)
const VideoExpired: ComponentType<IVideoExpired> = dynamic(() =>
  import('../../atoms/VideoExpired').then((module) => module.VideoExpired),
)

export interface IArticleVideo extends Block {
  video: Video
  pictures?: IPicture
  link?: string
  previewVideoUrl?: string
}

const PLAYER_ID = 'article-video-player-id'

export function ArticleVideo({
  video,
  pictures,
  'data-module': dataModule,
  link,
  previewVideoUrl,
}: IArticleVideo): JSX.Element {
  const isAmp = useAmp()
  const { video_id, title, program, specificFields, restriction, duration, isVertical } = video
  if (!video_id) return null

  return (
    <>
      <div className="ArticleVideo__Wrapper flex flex-column" data-module={dataModule}>
        {program?.name && (
          <div className="ArticleVideo__Details_Wrapper flex">
            <div className="ArticleVideo__Icon">
              <Icon name="video-logo" primaryColor={theme.cssVars.deepBlue} size="30px" />
            </div>
            <div className="ArticleVideo__Details flex flex-column">
              <span className="ArticleVideo__Details_Title">{title}</span>
              <span className="ArticleVideo__Details_Source">
                <span className="ArticleVideo__Details_Label">Source : </span>
                {program.name}
              </span>
            </div>
          </div>
        )}
        {isVertical ? <Picture {...pictures} /> : null}
        <div className="ArticleVideo__Player">
          {video.isExpired ? (
            <VideoExpired video={video} />
          ) : link && !isAmp ? (
            <Link url={link}>
              <PreviewVideo
                url={previewVideoUrl}
                pictures={pictures}
                duration={duration}
                triggerType="scroll"
                isVideoVertical={isVertical}
              />
            </Link>
          ) : (
            <PlayerLazyload
              title={title}
              image={specificFields.image}
              pictures={pictures}
              loadOn="scroll"
            >
              <PlayerVideo
                containerId={`${PLAYER_ID}-${video_id}`}
                videoId={video_id}
                autoplay={false}
                withPip
                restrictionWordings={restriction?.wordings}
              />
            </PlayerLazyload>
          )}
        </div>
      </div>

      <style jsx>{`
        .ArticleVideo__Wrapper {
          position: ${isVertical ? 'relative' : 'static'};
          grid-template-columns: auto ${isVertical ? '315px' : '100%'} auto;
        }

        .ArticleVideo__Player {
          padding: ${video.isExpired && !link?.length ? '0 30px' : '0'};
          max-width: ${isVertical ? '375px' : '100%'};
          aspect-ratio: ${isVertical ? '9/16' : '16/9'};
        }

        .ArticleVideo__Player :global(> div) {
          position: ${isVertical ? 'static' : 'relative'};
        }

        .ArticleVideo__Player :global(.PlayerVideo) {
          position: ${isVertical ? 'static' : 'relative'};
        }

        @media ${mixins.mediaQuery.tablet} {
          .ArticleVideo__Wrapper {
            grid-template-columns: auto ${isVertical ? '375px' : '100%'} auto;
          }
        }
      `}</style>
      <style jsx>{`
        .ArticleVideo__Wrapper {
          display: grid;
          margin-bottom: 50px;
          grid-template-areas:
            'title title title'
            '. video .';
        }

        .ArticleVideo__Icon {
          padding: 0 10px 0 0;
        }

        .ArticleVideo__Details_Wrapper {
          grid-area: title;
          padding: 20px 30px;
        }

        .ArticleVideo__Details_Source {
          color: ${theme.cssVars.deepBlue};
          font-size: 13px;
          font-weight: 700;
          line-height: 154%;
        }

        .ArticleVideo__Details_Label {
          color: ${theme.cssVars.midBlue};
          font-family: ${mixins.fonts.base.small.fontFamily};
          font-style: ${mixins.fonts.base.small.fontStyle};
          font-weight: ${mixins.fonts.base.small.fontWeight};
          font-size: ${mixins.fonts.base.small.fontSize};
          line-height: ${mixins.fonts.base.small.lineHeight};
        }

        .ArticleVideo__Details_Title {
          color: ${theme.cssVars.deepBlue};
          font-size: 20px;
          font-weight: 800;
          font-family: ${theme.cssVars.overpass};

          padding-top: 1px;
        }

        .ArticleVideo__Player {
          width: 100%;
          position: relative;
          grid-area: video;
        }

        .ArticleVideo__Wrapper :global(> .Picture) {
          display: inline-block;
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          grid-row: 2 / 2;
          grid-column: 1 / 4;
          overflow: hidden;
        }

        .ArticleVideo__Wrapper :global(> .Picture img) {
          width: 100%;
          height: 100%;
        }

        .ArticleVideo__Wrapper :global(> .Picture::after) {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          mix-blend-mode: hard-light;
          background: ${theme.cssVars.infoBlue};
          opacity: 0.25;
        }

        .ArticleVideo__Wrapper :global(> .Picture img) {
          filter: blur(5px) contrast(128%) hue-rotate(0deg) invert(0%) opacity(100%) saturate(120%)
            sepia(0%);
          mix-blend-mode: unset;
        }

        @media ${mixins.mediaQuery.tablet} {
          .ArticleVideo__Wrapper {
            margin: 0 30px 50px;
          }
        }
      `}</style>
    </>
  )
}
